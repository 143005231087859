<template>
  <div class="row">
    <PageTitle :title="'List Purchase Order'" />
    <!-- <div class="col-md-12 mb-5">
            <button v-if="tvalid.create" type="button" id="btnTambah" class="btn btn-dark float-end" @click="add"><i
                    class="fa fa-plus"></i> Tambah Purchase Order</button>
        </div> -->
  </div>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body p-9">
      <div class="row mb-3">
        <div style="flex: 0 0 auto; width: 89%"></div>
        <div style="flex: 0 0 auto; width: 11%">
          <button
            type="button"
            style="width: 100%"
            @click="btnFilter(showFilter)"
            id="btnFilter"
            class="btn btn-light-dark"
          >
            <i class="fa fa-sliders-h"></i> Filter
          </button>
        </div>
      </div>
      <div v-if="showFilter">
        <div class="row mb-2">
          <div class="col-md-6">
            <label for="" style="font-size: 14px">Nomor Purchase Order</label>
            <input
              type="text"
              class="form-control"
              id="inputNamaProduct"
              v-model="filter.n_po_code"
              placeholder="Masukkan Nomor Purchase Order"
            />
          </div>
          <div class="col-md-6">
            <label for="" style="font-size: 14px">Status</label>
            <el-select
              @click="selectStatusApproval"
              v-model="filter.status_id"
              filterable
              placeholder="Pilih Status"
              v-bind:id="'inputApproval'"
              :popper-class="'ApprovalSelect'"
            >
              <el-option
                v-for="item in lsApproval"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>

          <div class="col-md-12 mt-6">
            <label for="" style="font-size: 14px">Tanggal Masuk</label>
            <el-date-picker
              v-model="tanggalMasuk"
              type="daterange"
              size="large"
              start-placeholder="Pilih tanggal masuk awal"
              end-placeholder="Pilih tanggal masuk akhir"
              @change="changeDateTime($event, 'masuk')"
            >
            </el-date-picker>
          </div>
          <div class="col-md-12 mt-6">
            <label for="" style="font-size: 14px">Tanggal Perubahan</label>
            <el-date-picker
              v-model="tanggalPengajuan"
              type="daterange"
              size="large"
              start-placeholder="Pilih tanggal perubahan awal"
              end-placeholder="Pilih tanggal perubahan akhir"
              @change="changeDateTime($event, 'perubahan')"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="row mt-6">
          <div class="col-md-7">
            <button
              type="button"
              id="btnClear"
              class="btn btn-white"
              @click="clearFilter"
            >
              Kosongkan Filter
            </button>
          </div>
          <div class="col-md-5">
            <button
              type="button"
              id="btnApply"
              class="btn btn-dark"
              style="margin-right: 9px; width: 50%"
              @click="filterData"
            >
              Terapkan
            </button>
            <button
              type="button"
              id="btnClose"
              class="btn btn-secondary"
              style="width: 47%"
              @click="closeFilter"
            >
              Batal
            </button>
          </div>
        </div>
      </div>
      <Datatable
        v-if="refreshTable"
        ref="basetable"
        :table-header="tabelHeader"
        :dataurl="dataurl"
        :filter="listFilter"
        :search="search"
        :routeurl="routeurl"
        :deleteurl="deleteurl"
        :methodeurl="methodeurl"
      >
        <template v-slot:cell-po_code="{ row: data }">
          {{ data.po_code }}
        </template>
        <template v-slot:cell-pr_code="{ row: data }">
          {{ data.pr_code }}
        </template>
        <template v-slot:cell-entry_date="{ row: data }">
          {{ $mix.format_date_time(data.entry_date) }}
        </template>
        <template v-slot:cell-approval_status_name_vendor="{ row: data }">
          {{ data.approval_status_name_vendor }}
        </template>
        <template v-slot:cell-updated_date="{ row: data }">
          {{ $mix.format_date_time(data.updated_date) }}
        </template>
        <template v-slot:cell-action="{ row: data }">
          <button
            type="button"
            v-bind:id="'btnDetail' + data.po_code"
            @click="btnDetail(data.po_code, data.pr_code)"
            class="btn btn-sm btn-light-info"
          >
            <span class="fa fa-search"></span>
          </button>
        </template>
      </Datatable>
    </div>
  </div>
  <el-dialog v-model="dialogVisibleDelete" width="30%">
    <div class="text-center">
      <img align="" src="/media/fps/icon_trash.png" style="width: 20%" />
      <h5 class="mt-4 mb-4">Hapus data?</h5>
      <p>Anda yakin ingin menghapus data ini?</p>
      <el-input
        v-bind:id="'textareaReasonDelete'"
        v-model="reasondelete"
        type="textarea"
        :rows="4"
        name="reasondelete"
        placeholder="Masukkan Catatan"
        @input="typereason"
      >
      </el-input>
    </div>
    <template v-slot:footer>
      <span class="dialog-footer d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-dark mr-2"
          @click="btnHapus(valDelete)"
          :disabled="validateHapus"
        >
          Hapus</button
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-light"
          @click="dialogVisibleDelete = false"
        >
          Batal
        </button>
      </span>
    </template>
  </el-dialog>
</template>
<style lang="scss">
.el-range-editor {
  width: 100% !important;
}
</style>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Datatable from "@/components/kt-datatable/KTDatatableVendorId.vue";
import Multiselect from "@vueform/multiselect";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import PageTitle from "@/components/PageTitle.vue";
import CryptoJS from "crypto-js";
import sha256 from "crypto-js/sha256";

export default {
  components: {
    Datatable,
    Multiselect,
    PageTitle,
    CryptoJS,
    sha256,
  },
  data() {
    const fields = [
      { key: "po_code", name: "NO. PURCHASE ORDER", sortable: true },
      { key: "branch_id", name: "CABANG", sortable: true },
      { key: "department_code", name: "Departemen", sortable: true },
      { key: "employee_name", name: "PEMOHON PENGAJUAN", sortable: true },
      { key: "vendor_name", name: "Vendor", sortable: true },
      {
        key: "next_approval_name",
        name: "PERSETUJUAN SELANJUTNYA",
        sortable: true,
      },
      { key: "approval_status", name: "STATUS PERSETUJUAN", sortable: true },
      { key: "grand_total", name: "NILAI PENGAJUAN", sortable: true },
      { key: "entry_date", name: "TANGGAL PENGAJUAN", sortable: true },
      { key: "updated_date", name: "TANGGAL AKHIR PERUBAHAN", sortable: true },
      { key: "action", name: "Aksi", sortable: true, stickyColumn: true },
    ];
    return {
      dataurl: "/purchaseorder/v1/get-all",
      methodeurl: "post",
      routeurl: "procurement-master-purchase-order",
      deleteurl: "/purchaseorder/v1/deactivate-purchase-order",
      profile: JSON.parse(this.$store.getters.currentUser),
      tabelHeader: fields,
      search: null,
      reasondelete: "",
      loading: false,
      pageLink: [],
      tvalid: {
        create: false,
        delete: false,
        update: false,
        list: false,
        detail: false,
        action: false,
      },
      validateHapus: true,
      dialogVisibleDelete: false,
      valDelete: null,
      lsBranch: [],
      lsApproval: [],
      listFilter: [
        {
          vendor_id: "",
          vendor_type: "",
          po_code: "",
          status_id: "",
          entry_date_start: null,
          entry_date_end: null,
          updated_date_start: null,
          updated_date_end: null,
        },
      ],
      filter: {
        n_po_code: null,
        status_id: null,
        entry_date_start: null,
        entry_date_end: null,
        updated_date_start: null,
        updated_date_end: null,
      },
      timeoutQuery: null,
      refreshTable: true,
      showFilter: false,
      tanggalPengajuan: "",
      tanggalMasuk: "",
      profile: JSON.parse(this.$store.getters.currentUser),
      disableDept: false,
      disableBranch: false,
    };
  },
  mounted() {
    setCurrentPageBreadcrumbs("List Purchase Order", [
      // {
      //   title: "Procurement",
      //   link: "#",
      // },
    ]);
    this.filterData();
    this.getVendorStatus();
    this.pageLink = this.$store.state.BodyModule.isPageChild;
    for (var i in this.pageLink) {
      let row = this.pageLink[i];
      if (row == "Create") {
        this.tvalid.create = true;
      } else if (row == "Data Table") {
        this.tvalid.list = true;
      } else if (row == "Detail") {
        this.tvalid.detail = true;
      } else if (row == "Update") {
        this.tvalid.update = true;
      } else {
        this.tvalid.delete = true;
      }
      if (this.tvalid.detail) {
        this.tvalid.action = true;
      } else if (this.tvalid.delete) {
        this.tvalid.action = true;
      } else if (this.tvalid.detail == false && this.tvalid.delete == false) {
        this.tvalid.action = false;
      }
    }
    if (this.profile.data.vendor_type_name === "KURIR") {
      this.fetchHeadTableKurir();
    } else {
      this.fetchHeadTableProduksi();
    }
  },
  methods: {
    fetchHeadTableProduksi() {
      this.tabelHeader = [
        {
          key: "po_code",
          name: "NO. PO",
          sortable: true,
        },
        {
          key: "entry_date",
          name: "TANGGAL MASUK",
          sortable: true,
        },
        {
          key: "approval_status_name_vendor",
          name: "STATUS",
          sortable: true,
        },
        {
          key: "updated_date",
          name: "TANGGAL PERUBAHAN TERAKHIR",
          sortable: true,
        },
        {
          key: "action",
          name: "Aksi",
          sortable: true,
          stickyColumn: true,
        },
      ];
    },
    fetchHeadTableKurir() {
      this.tabelHeader = [
        {
          key: "po_code",
          name: "NO. PO",
          sortable: true,
        },
        {
          key: "pr_code",
          name: "NO. PR",
          sortable: true,
        },
        {
          key: "entry_date",
          name: "TANGGAL MASUK",
          sortable: true,
        },
        {
          key: "approval_status_name_vendor",
          name: "STATUS",
          sortable: true,
        },
        {
          key: "updated_date",
          name: "TANGGAL PERUBAHAN TERAKHIR",
          sortable: true,
        },
        {
          key: "action",
          name: "Aksi",
          sortable: true,
          stickyColumn: true,
        },
      ];
    },
    goBack() {
      return this.$router.go(-1);
    },

    statusApproval(val) {
      if (val === "STCPT") {
        return "COMPLETE";
      } else if (val === "STRJT") {
        return "REJECT";
      } else if (val === "STRTN") {
        return "REVISE";
      } else if (val === "STAPP") {
        return "APPROVE";
      } else if (val === "STSBT") {
        return "SUBMIT";
      }
    },
    getVendorStatus() {
      let params = {
        vendor_type: this.profile.data.vendor_type_id,
      };
      // start x-signature-key
      var sha256 = CryptoJS.algo.SHA256.create();
      sha256.update(JSON.stringify(params));
      var hash = sha256.finalize();
      var hashupper = hash.toString().toUpperCase();
      localStorage.setItem("signature_key", hashupper);
      // end x-signature-key

      this.$apiservice.setHeader();
      this.$apiservice
        .post("/purchaseorder/v1/get-vendor-status", params)
        .then((res) => {
          let data = res.data;
          this.lsApproval = [];
          for (var i in data) {
            this.lsApproval.push({
              value: res.data[i].child_code,
              label: res.data[i].desciption,
            });
          }
        });
    },
    typereason() {
      if (this.reasondelete.length > 0) {
        this.validateHapus = false;
      } else {
        this.validateHapus = true;
      }
    },
    selectCabang() {
      document
        .getElementsByClassName("cabangSelect")[0]
        .setAttribute("id", "selectCabangChange");
    },
    selectStatusApproval() {
      document
        .getElementsByClassName("ApprovalSelect")[0]
        .setAttribute("id", "selectApprovalChange");
    },
    branch() {
      this.$apihris
        .get("/master/v2/list-subdepartment-group")
        .then((res) => {
          this.lsBranch = [];
          for (var i in res.data.data) {
            this.lsBranch.push({
              value: res.data.data[i].sub_department_group_code,
              label:
                res.data.data[i].sub_department_group_code +
                " - " +
                res.data.data[i].sub_department_group_name,
            });
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push({ name: "401" });
          } else if (err.response.status == 404) {
            this.$router.push({ name: "404" });
          } else if (err.response.status == 500) {
            this.$router.push({ name: "500" });
          }
        });
    },
    approval() {
      this.$apiservice
        .get("/purchaseorder/v1/get-approval-status-list")
        .then((res) => {
          this.lsApproval = [];
          for (var i in res.data) {
            this.lsApproval.push({
              value: res.data[i].id,
              label: res.data[i].text,
            });
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.$router.push({ name: "401" });
          } else if (err.response.status == 404) {
            this.$router.push({ name: "404" });
          } else if (err.response.status == 500) {
            this.$router.push({ name: "500" });
          }
        });
    },
    changeDateTime(val, key) {
      if (key == "perubahan") {
        if (val != null) {
          let start_date = new Date(val[0]);
          let end_date = new Date(val[1]);
          start_date.setHours(0);
          start_date.setMinutes(0);
          start_date.setSeconds(0);
          end_date.setHours(23);
          end_date.setMinutes(59);
          end_date.setSeconds(59);
          this.filter.updated_date_start = start_date;
          this.filter.updated_date_end = end_date;
          this.listFilter[0].updated_date_start =
            this.filter.updated_date_start;
          this.listFilter[0].updated_date_end = this.filter.updated_date_end;
        } else {
          this.filter.updated_date_start = null;
          this.filter.updated_date_end = null;
          this.listFilter[0].updated_date_start = null;
          this.listFilter[0].updated_date_end = null;
        }
      } else {
        if (val != null) {
          let start_date = new Date(val[0]);
          let end_date = new Date(val[1]);
          start_date.setHours(0);
          start_date.setMinutes(0);
          start_date.setSeconds(0);
          end_date.setHours(23);
          end_date.setMinutes(59);
          end_date.setSeconds(59);
          this.filter.entry_date_start = start_date;
          this.filter.entry_date_end = end_date;
          this.listFilter[0].entry_date_start = this.filter.entry_date_start;
          this.listFilter[0].entry_date_end = this.filter.entry_date_end;
        } else {
          this.filter.entry_date_start = null;
          this.filter.entry_date_end = null;
          this.listFilter[0].entry_date_start = null;
          this.listFilter[0].entry_date_end = null;
        }
      }
    },
    cari(e) {
      let val = this.search;
      if (val.length > 3) {
        this.listFilter[0].product_name = this.search;
        this.forceUpdate();
      }
    },
    add() {
      this.$router.push({ name: "procurement-master-purchase-order-create" });
    },
    filterData() {
      this.listFilter[0].vendor_id = this.profile.data.vendor_id;
      this.listFilter[0].vendor_type = this.profile.data.vendor_type_id;
      this.listFilter[0].po_code = this.filter.n_po_code;
      this.listFilter[0].status_id = this.filter.status_id;
      this.listFilter[0].entry_date_start = this.filter.entry_date_start;
      this.listFilter[0].entry_date_end = this.filter.entry_date_end;
      this.listFilter[0].updated_date_start = this.filter.updated_date_start;
      this.listFilter[0].updated_date_end = this.filter.updated_date_end;
      this.forceUpdate();
    },
    btnFilter(value) {
      if (value) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
    },
    closeFilter() {
      this.showFilter = false;
    },
    clearFilter() {
      this.filter.n_po_code = null;
      this.filter.status_id = null;
      this.filter.entry_date_start = null;
      this.filter.entry_date_end = null;
      this.filter.updated_date_start = null;
      this.filter.updated_date_end = null;
      this.listFilter[0].po_code = this.filter.n_po_code;
      this.listFilter[0].status_id = this.filter.status_id;
      this.listFilter[0].entry_date_start = this.filter.entry_date_start;
      this.listFilter[0].entry_date_end = this.filter.entry_date_end;
      this.listFilter[0].updated_date_start = this.filter.updated_date_start;
      this.listFilter[0].updated_date_end = this.filter.updated_date_end;
      this.tanggalPengajuan = "";
      this.tanggalMasuk = "";
      this.forceUpdate();
    },
    btnDetail(val, val2) {
      this.$router.push({
        name: "vendor-list-po-purchase-order-detail",
        params: { id: val },
      });

      if (this.profile.data.vendor_type_name === "KURIR") {
        localStorage.setItem("pr_code_kurir", val2);
      }
    },
    btnConfirmHapus(val) {
      this.dialogVisibleDelete = true;
      this.valDelete = val;
    },
    btnHapus(val) {
      let param = {
        po_code: val,
        delete_by: this.profile.data.username,
        reason: this.reasondelete,
      };
      this.dialogVisibleDelete = false;
      this.$apiservice.setHeader();
      this.$apiservice.post(this.deleteurl, param).then((res) => {
        Swal.fire({
          title: "Data Terhapus",
          html: "Data berhasil dihapus",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn fw-bold btn-success",
          },
        }).then(() => {
          this.reasondelete = "";
          this.validateHapus = true;
          this.forceUpdate();
        });
      });
    },
    convertOnTime(val) {
      if (val == null || val == 0) {
        val = 0;
      } else {
        val = 1;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    forceUpdate() {
      this.refreshTable = false;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.el-select {
  width: 100%;
}

.for-cari::placeholder {
  font-weight: 600 !important;
  font-family: Arial, "Font Awesome 5 Free";
}
</style>
